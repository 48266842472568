<template>
  <v-card flat class="cont-shadown-rounded" :id="`Grafica${nIdGrafica}`">
    <v-container class="px-3 pt-2 pb-0 header-blue-light" fluid >
      <v-row align="center" justify="start" >
        <v-col lg="7" md="7" sm="12" xs="12" cols="12" class="py-0">
          <span class="text-medium font-size-2" style="color:#171717;">{{ titulo }}</span>
        </v-col>
        <v-col lg="5" md="5" sm="12" cols="12" class="pt-4 col-right">
          <v-btn v-if="isfiltro != '0'" color="btndest" class="no-uppercase btn-rounded d-flex mx-2" small depressed dark @click="modalCalendario = true, filtroCalendario()">Filtrar<v-icon size="13px" right>fa-calendar</v-icon></v-btn>
          <CalendarioModal 
            v-model="modalCalendario"
            @rango="rangoSeleccionado">
          </CalendarioModal>
          <v-menu class="d-flex mx-2" offset-y :close-on-content-click="false" transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-if="isexportar != '0'" v-on="on" color="primary" outlined class="no-uppercase btn-rounded" small depressed> Exportar <v-icon size="13px" right>fa-file-export</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="EportDPF()">
                <v-list-item-content>
                  <v-list-item-title> <v-icon left>fa-file-export</v-icon>Exporta a PDF la grafica seleccionada</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0">
          <v-progress-linear v-if="bCargando" indeterminate color="primary"></v-progress-linear>  
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="sRangoSeleccionado.length > 5" class="py-1 mb-2 btndest" fluid >
      <v-row align="center" justify="start">
        <v-col lg="11" md="11" sm="10" xs="10" cols="10" class="py-0">
          <span class="text-medium font-size-4" style="color:white;"><strong>Filtro Activo: </strong>{{ sRangoSeleccionado }}</span>
        </v-col>
        <v-col lg="1" md="1" sm="2" xs="2" cols="2" class="py-0 text-right ma-0" justify="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" outlined small icon v-bind="attrs" v-on="on" @click="limpiarFiltro">
                  <v-icon size="17px">mdi-filter-off</v-icon> 
              </v-btn>
            </template>  
            <span>Eliminar Filtro</span>
          </v-tooltip>  
        </v-col>
      </v-row>
    </v-container>  
    <v-container fluid class="pt-2">
      <v-row dense class="ma-0 pa-0">
        <v-col cols="12" xs="6" md="4" class="pa-0 ma-0">
          <v-select
            v-model="sTypechartComponent"
            :items="aTypesComponent"
            :disabled="bDisableInput"
            item-text="sTextChart"
            item-value="sChart"
            height="30px"
            label="Tipo de gráfica"
            persistent-hint
            outlined
            dense>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text v-if="!bValidateViewChart" class="headline font-weight"
      v-html="sTextCard">
    </v-card-text>
    <div class="containerChartB ">
      <GChart 
        :key="keyRedibujar"
        :type="sTypeChartView"
        :data="aDataChart"
        :options="oChartOptions"
        :resizeDebounce="500">
      </GChart>
    </div>
  </v-card>
</template>
<script>
import { GChart } from 'vue-google-charts';
import CalendarioModal from '../CalendarioModal.vue';
import mixinDash from "@/components/Seif_Modulos/control-activos/DashComponentes/mixinDashBoard.js"
import axiosServices from '@/services/axiosServices.js'
import ExporterGraf from "vue-chartjs-exporter";

  export default {
    name: "GraficasActivos",
    mixins: [mixinDash],
    components : {
      GChart,
      CalendarioModal
    },
    data() {
      return {
        sRangoSeleccionado: '',
        bCargando: false,
        keyRedibujar: 0,      // Lo empleo par redibujar la grafica
        menu2: false,
        modalCalendario: false,
        bValidateViewChart   : true,
        sTypeChartView       : (this.sTypeChart !== "") ? this.sTypeChart : "ColumnChart",
        sTextCard: 'No existe información.',
        aChartData: [],
        // aChartData: this.aSetData.filter(this.filterData),
        sTypechartComponent: { sTextChart: '', sChart: '' },
        aTypesComponent: [
          {sTextChart: 'Columnas', sChart: 'ColumnChart'},
          {sTextChart: 'Lineas', sChart: 'LineChart'},
          {sTextChart: 'Torta', sChart: 'PieChart'},
          {sTextChart: 'Barras', sChart: 'BarChart'},
          {sTextChart: 'Area', sChart: 'AreaChart'},
        ],
        oChartOptions: {
          legend: { 
            // position: (this.sSection == '10') ? 'right' : 'none', // muertra nomenclatur de colores
            position: 'none',
            textStyle : {
              fontSize: 12 // or the number you want
            }
          },
          // colors: (this.sSection == '9') ? ['#00447D'] : this.aRandomColor,  // maneja el color de las barras
          colors: this.aRandomColor,  // maneja el color de las barras
          vAxes: {
            0: {title: 'No. registros'},
          },
          hAxis: {
            textStyle : {
              fontSize: 12 // or the number you want
            }
          },
          // height: (this.sSection == '9') ? 250 : (this.sSection == '10') ? 450 : 250,
          height:  250,
          width: "auto",
          pointSize: 5,
        },
        aRandomColor: [],
        bDisableInput: false
      }
    },
    mounted () {
    },
    created() {
      this.aDataChart = this.aDatab
    },
    methods: {
      async getData(filtro) {
        this.bCargando = true
        const aData = await axiosServices.getConfiguration(`/infodashGraficas_filtro`, true, `nIdDasBoard=${this.nIdDasBoard}&nIdGrafica=${this.nIdGrafica}&sFiltro=${filtro}`)
        this.aDataChart = aData.aData[0].aDatab
        this.keyRedibujar++
        this.bCargando = false

      },
      limpiarFiltro (){
        this.sRangoSeleccionado = ""
        this.getData("")
      },
      getRamdomColor(nLengthHeader) {
        let aColors = []
        for (let i = 1; i<=nLengthHeader; i++) {
          let randomColor = Math.floor(Math.random()*16777215).toString(16)
          aColors.push(`"#${randomColor}"`)
        }
        this.aRandomColor = aColors
        
      },
      addColums(aInfo) {
        aInfo[0].push({role: 'style'})
        aInfo[0].push({role: 'annotation'})
        aInfo.forEach((element, i) => {
          if (i != 0) {
            element.push(`#${Math.floor(Math.random()*16777215).toString(16)}`)
            element.push(`${element[1]}`)
          }
        });
        this.aChartData = aInfo
      },
      rangoSeleccionado(rangovalor){
        this.getData(JSON.stringify(rangovalor))
        this.sRangoSeleccionado = rangovalor.join(' al ')
      },
      async EportDPF() {
        let grafica = document.getElementById(`Grafica${this.nIdGrafica}`)
        const exp = new ExporterGraf([grafica])
        exp.export_pdf().then((pdf) => pdf.save("Imagen.pdf"))
      },
      filtroCalendario(){
      this.$analytics.logEvent('ControlActivosDash', { filtroCalendario: 1})// CAPTURA DE DATOS ANALYTICS
      }
    },
    watch: {
      aSetData(aNewValue) {
        aNewValue.filter(this.filterData);
      },
      sTypechartComponent(sNewValue) {
        this.sTypeChartView = sNewValue;
      }
    },
  }
</script>
<style lang="scss">
  .containerChartB{
    border-radius: 10px;
    overflow: hidden;
  }
</style>