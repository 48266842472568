export default {
  props: {
    titulo: {
      type: String,
      default: ""
    },
    aDatab: {  // puede llegar objeto o array segun el tipo de grafica por eso no defino
      // type: Object
    },
    sTypeChart: {
      type: String,
      default: ""
    },
    sSection: {
      type: String,
      default: ''
    },
    isfiltro: {
      type: String,
      default: ''
    },
    isexportar: {
      type: String,
      default: ''
    },
    nIdDasBoard : {
      type: String,
      default: ''      
    },
    nIdGrafica : {
      type: String,
      default: ''      
    },
  }

}