<template>
  <div>
    <v-card
      flat
      min-width="200"
      class="card-activos cont-shadown-rounded">
      <v-card-text>
        <v-container fluid>
          <v-row dense align="center" class="my-3">
            <v-col sm="12" md="12" lg="12" xl="12">
              <span class="text-primary subtitulo sergio">{{titulo}}</span>
            </v-col>
          </v-row>
          <v-row dense justify="space-between" align="center" no-gutters>
            <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="primercol">
              <span class="titulo-numero text-semibold">{{ aDatab.Valor }}</span>
            </v-col>
            <v-col cols="5" sm="5" md="5" lg="5" xl="5" class="segundocol">
              <div class="fecha-container">
                <span class="text-gray font-light">{{ aDatab.Subtitulo }} : </span>
                <span class="text-gray font-light">{{ aDatab.Valor2 }}</span>
              </div>
              <div class="flecha-container">
                <div v-if="aDatab.Visualizaicono == '1'">
                  <v-icon v-if="(parseInt(aDatab.Valor) > parseInt(aDatab.Valor2))" color="red" size="100" class="anima-up">fas fa-caret-up</v-icon>
                  <v-icon v-else-if="(parseInt(aDatab.Valor) < parseInt(aDatab.Valor2))" color="green" size="100" class="anima-down">fas fa-caret-down</v-icon>
                  <v-icon v-else color="blue" size="100">fas fa-minus</v-icon>
                </div>
                <div v-else>
                  <v-icon color="white" size="40">fas fa-minus</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import mixinDash from "@/components/Seif_Modulos/control-activos/DashComponentes/mixinDashBoard.js"

export default {
  name: "cardsActivos",
  mixins: [mixinDash],
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.card-activos{
  padding: 0px!important;
}
.segundocol{
  text-align: center;
}
.flecha-container{
  i{
    line-height: 0.5em;
  }
}
.primercol{
  text-align: center;
}
.titulo-numero{
  font-size: 3.5em;
  color: #707070;
}

</style>