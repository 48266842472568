<template>
  <div id="DashBord">
    <v-container v-if="oDataComponent.Acceso=='1'" grid-list-xs class="pa-0 ma-0 pt-2" fluid>
      <v-row class="pa-0 ma-0 titlesdark--text" align="center" justify="start"  >
        <v-col md="12" sm="12" cols="12" class="py-0">
          <span class="pl-1 text-light font-size-2" >{{oDataComponent.TituloGeneral}}</span>
          <div class="col-right cont-botones ml-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mx-1 btn-back-white" outlined small icon v-bind="attrs" v-on="on" @click="getData">
                    <v-icon size="20px">mdi-reload</v-icon> 
                </v-btn>
              </template>  
              <span>Refrescar Información</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mx-1 btn-back-white" outlined small icon v-bind="attrs" v-on="on" @click="EportDPF">
                    <v-icon size="20px">mdi-file-export-outline</v-icon> 
                </v-btn>
              </template>  
              <span>Generar PDF</span>
            </v-tooltip>
          </div>
          <v-progress-linear v-if="bCargando" indeterminate color="primary"></v-progress-linear>  
        </v-col>
      </v-row>
      <v-flex xs12 class="">
         <v-row>
           <v-col 
            v-for="(item, i) in oDataComponent.aData"
            :key="'item'+i" cols="12"
            sm="12" :md="item.Tamano" :lg="item.Tamano" :xl="item.Tamano">
            <component
              :is="item.Componente"
              :nIdDasBoard="nIdDasBoard"
              :nIdGrafica="item.IdGrafica"
              :titulo="item.Titulo"
              :isfiltro="item.isFiltro"
              :isexportar="item.isExportar"
              :aDatab="item.aDatab"
            >
            </component>
            </v-col>
        </v-row>
      </v-flex>
    </v-container>
    <v-container v-if="oDataComponent.Acceso=='0'" grid-list-xs class="pa-0 ma-0 pt-2" fluid>
        <v-layout fluid row  wrap justify-center align-center class="no-acceso">
          <v-flex xs12 sm6 v-for="lock in 4" :key="lock" class="pa-3">
            <v-skeleton-loader
              :loading="false"
              class="mx-auto"
              max-width="100%"
              type="list-item-avatar-three-line"
            >
            </v-skeleton-loader>
          </v-flex>
          <div class="mensaje">
            <div class="cont-icon">
              <v-icon size="40px" class="icon">mdi-lock-off-outline</v-icon>
            </div>
            <div class="texto-mensaje pa-3">
              <span class="text-primary font-size-1">
                No posee permisos sobre la dashboard
              </span>
            </div>
          </div>
        </v-layout>    </v-container>
  </div>
</template>
<script>
import CardComparativa from '@/components/Seif_Modulos/control-activos/DashComponentes/CardsActivos.vue'
import GraficasActivos from '@/components/Seif_Modulos/control-activos/DashComponentes/GraficasActivos.vue'
import axiosServices from '@/services/axiosServices.js'
import ExporterGraf from "vue-chartjs-exporter";


export default {
  components:{
    CardComparativa,
    GraficasActivos,
  },
  name:'ControlActivos',
  data() {
    return {
      bCargando : false,
      nIdDasBoard : "1" ,     // Indica el id Del dashboard a mostrar
      oDataComponent : {},
    }

  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData() {
     this.bCargando = true
     const aData = await axiosServices.getConfiguration(`/infodashGraficas`, true, `nIdDasBoard=${this.nIdDasBoard}&nIdPerfil=${this.$store.state.idPerfil}`)
     this.oDataComponent = aData.aData
     this.bCargando = false
    },
    EportDPF() {
      let grafica = document.getElementById("DashBord")         // obtengo el componente a exportar
      const exp = new ExporterGraf([grafica])                   // Se arma un arreglo con los componentes a exportar pero como solo tengo uno - dejo uno
      exp.export_pdf().then((pdf) => pdf.save("Dash.pdf"))    // listo se exprta
    },
  },
}
</script>
<style lang="scss">
.container-dash{
  text-align: center;
  h2{
    padding: 5px;
    line-height: 1.2em;
  }
}
.cont-botones{
  // float: right;
  display: inline-block;
}
.btn-back-white{
  background: white;
}
 .no-acceso{
    margin: 0;
    position: relative;
    text-align: center;
    height: 40vh;
  .mensaje{
      position: absolute;
      margin: 0 auto;
      top: 20%;
      width: 100%;
    }

    .cont-icon{
      background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%);
      width: 80px;
      height: 80px;
      border: solid #FFF 1px;
      box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: .2);
      margin: 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      i{
      font-size: 55px;
      color:var(--v-primary-base);
      }
    }
    .texto-mensaje{
      border-radius: 10px;
      border: solid #FFF 1px;
      margin-top:5px;
      background: linear-gradient(0deg, rgba(218,218,218,0.6) 0%, rgba(238,238,238,0.6) 100%);      
    }
    }
</style>

// El formato del arreglo es 
/*      old_oDataComponent:{
        "TituloGeneral": "Control de Bicicletas",
        "aData": [
          {
            "Componente": "CardComparativa",
            "Tamano": "3",
            "isFiltro": "0",
            "isExportar": "0",
            "Titulo": "Total Bicicletas Ingresadas",
            "aDatab": 
              {
                "Valor":"800",
                "Valor2":"800",
                "Visualizaicono":"1",
                "Titulo":"subtitulo1",
                "Subtitulo":"Marzo"
              }
            
          },
          {
            "Componente": "GraficasActivos",
            "Tamano": "9",
            "isFiltro": "1",
            "isExportar": "1",
            "Titulo": "# Bicicletas Registradas",
            "aDatab": [
              ['Fecha', 'Sales'],
              ['01-02-2021', 1170],
              ['01-01-2021', 1000],
              ['01-03-2021', 660],
              ['01-04-2021', 1030],
              ['01-05-2021', 1000],
              ['01-06-2021', 1170],
              ['01-07-2021', 660,],
              ['01-08-2021', 1030],
              ['01-09-2021', 1000],
              ['01-10-2021', 1170],
              ['01-11-2021', 660],
              ['01-12-2021', 1030],
            ],
          },
          {
            "Componente": "CardComparativa",
            "Tamano": "4",
            "isFiltro": "0",
            "isExportar": "0",
            "Titulo": "Bicicletas Registradas Hoy",
            "aDatab": 
              {
                "Valor":"800",
                "Valor2":"900",
                "Visualizaicono":"1",
                "Subtitulo":"Marzo"
              }
          },
          {
            "Componente": "GraficasActivos",
            "Tamano": "8",
            "isFiltro": "1",
            "isExportar": "1",
            "Titulo": "# Bicicletas Registradas por Guarda",
            "aDatab": [
              ['Fecha', 'Sales'],
              ['01-02-2021', 1170],
              ['01-01-2021', 1000],
              ['01-03-2021', 660],
              ['01-04-2021', 1030],
              ['01-05-2021', 1000],
              ['01-06-2021', 1170],
              ['01-07-2021', 660,],
              ['01-08-2021', 1030],
              ['01-09-2021', 1000],
              ['01-10-2021', 1170],
              ['01-11-2021', 660],
              ['01-12-2021', 1030],
            ],
          },
        ]
      }
*/
