<template>
  <div>
      <v-dialog
        v-model="showcalendar"
        persistent
        width="390px"
      >
      <v-card flat class="cont-shadown-rounded">
        <v-toolbar color="headformsdark" height="45" elevation="0" dense dark>
          <v-spacer></v-spacer>
          <span class="mdlHeadForms">Seleccionar rango</span>
          <v-spacer></v-spacer>
          <v-btn 
            color="btncolor-dark"
            @click="$emit('click', false)"
            icon
            small
            dark
            outlined>
            <v-icon size="15px">fa fa-times</v-icon>
          </v-btn>
        </v-toolbar>
       <v-row>
        <v-col cols="12" sm="12" class="text-center">
          <v-date-picker
            v-model="dates"
            range
            no-title
            :min="datahoy"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            locale="es-419"
            color="primarydark"
            width="100%"
          ></v-date-picker>
        </v-col>
        <v-col cols="12" sm="12" >
          <v-text-field
            v-model="dateRangeText"
            label="Rango seleccionado"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
          <!-- <h2>{{dateRangeText}}</h2> -->
        </v-col>
        <v-col cols="12" sm="12" class="text-center">
          <v-btn dark outlined color="btncolor" class="mx-1" @click="limpiarFechas()">LIMPIAR</v-btn>
          <v-btn dark outlined color="btncolor" class="mx-1" @click="aplicarRango(), $emit('click', false) " >APLICAR</v-btn>
        </v-col>
      </v-row>
      </v-card>
      </v-dialog>
  </div>
</template>
<script>
export default {
  name:'CalendarioModal',
  model:{
    prop: 'showcalendar',
    event: 'click'
  },
  props:{
      showcalendar:{
        type:Boolean,
        default:false,
    }
  },
  data() {
    return {
        dates:      ['',''],
        datahoy:    null
    }
  },
  mounted() {
    let hoy = new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1);
    this.datahoy = hoy.toISOString().substr(0, 10)
  },
  methods: {
    aplicarRango(){
      this.$emit('rango', this.dates)
    },
    limpiarFechas(){
      this.dates = ['','']
    }
  },
  computed:{
    dateRangeText () {
      return this.dates.join(' - ')
    },
  }
}

</script>